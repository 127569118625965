.projects .container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto ,1fr);
  margin-top: 2em;
}

.projects .card {
  display: grid;
  grid-column: 1 / 4;
  grid-template-columns: subgrid;
}

.projects .card > img {
  width: 100%;
  max-width: 300px;
  grid-column: 1 / 1;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 5px;
}

.projects .desc {
  grid-column:  2 / 4;
  place-content: center;
}

.projects ul {
  display: flex;
  list-style: none;
  gap: 1em;
}

.projects li {
  background-color: rgba(0, 0, 0, 0.308);
  font-weight: 500;
  color: rgba(230, 212, 48, 0.719);
  padding: .1em .2em;
  border-radius: 5px;
}

.projects .card:nth-of-type(even) img {
  grid-column: 4 / 4;
  grid-row: 1;
}

.projects .card:nth-of-type(even) .desc {
  grid-column: 1 / 3;
  grid-row: 1;
}

.desc > *:not(:nth-child(4)) {
  margin-bottom: .5em;
}

.projects .container .card:nth-of-type(even) * {
  text-align: right;
  justify-content: end;
}

.projects a {
  color: rgb(67, 67, 243);
  display: inline-block;
  text-decoration: none;
  
}

.projects a:hover {
  color: dodgerblue;
}

.projects span:not(a span) {
  display: inline-block;
  margin: 0 .5em;
}