* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  gap: 2em;
}

body {
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  font-size: calc(10px + 1vmin);
  padding: 1em 3em;
  max-width: 1200px;
  margin: 0 auto;
  background: rgb(28,31,62);
background: radial-gradient(circle, rgba(28,31,62,1) 11%, rgba(28,31,52,1) 78%, rgba(11,28,52,1) 93%);
  background-position: 50% 200px;
  color: white;
  /* animation: bgmove infinite ease 30s; */
}

@keyframes bgmove {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 50% 25%;
  }

  50% {
    background-position: 150% 1000px;
  }

  75% {
    background-position: 200% 5000px;
  }

  100% {
    background-position: 0% 100%;
  }
  
}

p {
  color: rgba(197, 197, 197, 0.753);
}

.App > * {
  margin-bottom: 4em;
}

.App > * > h2 {
  /* box-shadow: 0px .25em 0px -1px black; */
  color: #89a4b4;
  margin-bottom: .5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media (max-width: 690px) {

  .Nav-Bar {
    text-align: center;
    justify-content: center !important;
    padding: 0;
    margin: 0;
    opacity: 75%;
  }

  .Nav-Bar ul {
    display: none !important;
    
  }

  .About {
    display: flex !important;
    flex-direction: column;
  }

  .About img {
    max-width: 200px !important;
    aspect-ratio: 1/1;
  }

  .contact-button {
    
    justify-self: end !important;
    display: block !important;
  }

  .container, .container .card { 
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .container ul {
    flex-wrap: wrap;
    justify-content: start !important;
  }

  .container .desc *  {
    text-align: left !important;
  }
}