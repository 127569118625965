.certifications a:hover {
  background: rgba(197, 197, 197, 0.253);
}

.certifications a {
  color: white;
  text-decoration: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.certifications .container {
  margin-top: 2em;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.certifications .container .card {
  text-align: center;
  line-height: 1.5;
  position: relative;
}

.certifications img {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  display: block;
  margin-bottom: 1em;
}