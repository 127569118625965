.Nav-Bar {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    padding: 1em 0;
    align-items: baseline;
}
.Nav-Links {
    display: flex;
    list-style-type: none;
}

.Nav-Links a {
    text-decoration: none;
    color: inherit;
}

.Nav-Links a:hover {
    opacity: 70%;
    color: dodgerblue;
}

.Nav-Bar h1 {
    font-size: x-large;
}